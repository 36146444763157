import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`*`}{`We will reopen today for the 10:30 class and will be back on our
normal schedule the rest of the day.`}</em></strong></p>
    <p>{`Dynamic Step Ups 4×4/leg (24/20″)`}</p>
    <p>{`Back Squat 4×4 to a 4RM`}</p>
    <p>{`Single Leg BLB’s 4×8/leg`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 AMRAP of:`}</p>
    <p>{`5 Pistols/Leg`}</p>
    <p>{`10 Box Jumps (24/20)`}</p>
    <p>{`15 KBS (53/35)`}</p>
    <p><strong parentName="p">{`*`}{`The Granite Games Throwdown in coming up January 12th.  Get your
teams together and registered soon to reserve your spot!  If you’d like
to be on a team but don’t have 2 other teammates please put your name on
the white board in back and we’ll get you on a team. `}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://competitioncorner.net/events/1549?fbclid=IwAR0ST57-McRs4RyVzEL5D6sr_LV403-KCqTNNEKweUXuq32ASGMdLOHFY4s#.W_biri3Mz1w"
      }}>{`https://competitioncorner.net/events/1549?fbclid=IwAR0ST57-McRs4RyVzEL5D6sr_LV403-KCqTNNEKweUXuq32ASGMdLOHFY4s#.W_biri3Mz1w`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      